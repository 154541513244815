var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('SearchBar',{attrs:{"searchLabel":"Search by title..."},on:{"search":function($event){_vm.search = $event;
          _vm.loading = true;
          _vm.newsFeeds = [];
          _vm.options.page = 1;}}})],1),_c('v-col',{attrs:{"align":"right"}},[_c('NewsFeedDetail',{attrs:{"action":'add'},on:{"submitNewsFeed":function($event){return _vm.submitNewsFeed($event)}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"msaBlue white--text"},[_vm._v(" News Feed ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.newsFeeds,"server-items-length":_vm.newsFeedCount,"footer-props":{
            'items-per-page-options': [20, 30, 50],
          },"options":_vm.options,"items-per-page":_vm.perPage,"page":_vm.page},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_c('span',{staticClass:"msaBlue--text text-subtitle-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.description)+" ")])]),_c('td',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.type)+" ")]),_c('td',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm._f("filterAsLocalDate")(item.createdAt))+" ")]),_c('td',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"text":"","small":"","href":item.url,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"msaBlue"}},[_vm._v(" mdi-export-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('NewsFeedDetail',{key:_vm.reload,attrs:{"action":'edit',"newsFeed":item},on:{"submitNewsFeed":function($event){_vm.submitNewsFeed($event);
                    _vm.reload = !_vm.reload;},"close":function($event){_vm.reload = !_vm.reload}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"text":"","small":""},on:{"click":function($event){return _vm.deleteNewsFeed(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])]}}],null,false,2682844971)}):_vm._e()],1)],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-data-table',{attrs:{"hide-default-header":"","loading":_vm.loading,"items":_vm.newsFeeds,"server-items-length":_vm.newsFeedCount,"footer-props":{
      'items-per-page-options': [20, 30, 50],
    },"options":_vm.options,"items-per-page":_vm.perPage,"page":_vm.page},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-card',{staticClass:"my-3"},[_c('v-card-title',{staticClass:"text-subtitle-1 msaBlue white--text"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-card-text',{staticClass:"pt-2 black--text"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"text":"","small":"","href":item.url,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"msaBlue"}},[_vm._v(" mdi-export-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('NewsFeedDetail',{key:_vm.reload,attrs:{"action":'edit',"newsFeed":item},on:{"submitNewsFeed":function($event){_vm.submitNewsFeed($event);
              _vm.reload = !_vm.reload;},"close":function($event){_vm.reload = !_vm.reload}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"text":"","small":""},on:{"click":function($event){return _vm.deleteNewsFeed(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)],1)]}}],null,false,1122409472)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }