<template>
  <v-dialog v-model="dialog" width="750px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="action == 'add'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            v-blur
            data-testid="add-news-feed"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>Add</span>
      </v-tooltip>
      <v-tooltip bottom v-if="action == 'edit'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="msaBlue"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Add News Feed </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col class="py-0 mt-5 font-weight-bold"> Title: </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                class="py-0"
                required
                counter
                maxlength="2000"
                outlined
                :rules="rules.title"
                v-model="title"
                data-testid="title"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 font-weight-bold"> Description: </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-textarea
                class="py-0"
                outlined
                counter
                maxlength="4000"
                v-model="description"
                data-testid="description"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 font-weight-bold"> URL: </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                class="py-0"
                outlined
                counter
                maxlength="1000"
                v-model="url"
                data-testid="url"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 font-weight-bold"> Type: </v-col>
          </v-row>
          <v-row>
            <v-col align="left" class="py-0">
              <v-select
                :items="newsFeedTypes"
                label="Select a news feed type"
                dense
                flat
                solo
                outlined
                required
                :rules="rules.newsFeedType"
                v-model="typeId"
                chips
                data-testid="news-feed-type"
              ></v-select>
            </v-col>
            <v-col align="right" class="pr-0">
              <v-btn
                plain
                class="msaBlue--text font-weight-bold"
                @click="close()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col align="right" class="pl-0" md="2">
              <v-btn
                class="msaBlue white--text"
                @click="submitNewsFeedDetail()"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'NewsFeedDetail',
  props: {
    newsFeed: {
      type: Object,
    },
    action: {
      type: String,
    },
  },
  computed: {},
  watch: {},
  data() {
    return {
      url: '',
      typeId: 0,
      title: '',
      description: '',
      dialog: false,
      valid: false,
      rules: {
        title: [(v) => !!v || 'Title is required.'],
        newsFeedType: [(v) => !!v || 'Select a news feed type.'],
      },
      newsFeedTypes: [
        {
          text: 'Recent News',
          value: 1,
        },
        {
          text: 'Legislative Update',
          value: 2,
        },
      ],
    };
  },
  methods: {
    submitNewsFeedDetail() {
      if (this.$refs.form.validate()) {
        if (this.action == 'add') {
          this.$emit('submitNewsFeed', {
            title: this.title,
            description: this.description,
            typeId: this.typeId,
            url: this.url,
          });
        } else {
          this.$emit('submitNewsFeed', {
            id: this.newsFeed.id,
            title: this.title,
            description: this.description,
            typeId: this.typeId,
            url: this.url,
          });
        }
        this.dialog = false;
      }
    },
    close() {
      this.dialog = false;
      if (this.action == 'add') {
        this.title = '';
        this.description = '';
        this.typeId = 0;
        this.url = '';
      }
      this.$emit('close');
    },
  },
  mounted() {
    if (this.newsFeed != undefined) {
      this.title = this.newsFeed.title;
      this.description = this.newsFeed.description;
      this.url = this.newsFeed.url;
      this.typeId = this.newsFeed.typeId;
    }
  },
};
</script>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
