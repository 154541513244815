<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          @search="
            search = $event;
            loading = true;
            newsFeeds = [];
            options.page = 1;
          "
          searchLabel="Search by title..."
        />
      </v-col>
      <v-col align="right">
        <NewsFeedDetail
          :action="'add'"
          @submitNewsFeed="submitNewsFeed($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> News Feed </v-card-title>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="newsFeeds"
            :server-items-length="newsFeedCount"
            :footer-props="{
              'items-per-page-options': [20, 30, 50],
            }"
            :options.sync="options"
            :items-per-page.sync="perPage"
            :page.sync="page"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <span class="msaBlue--text text-subtitle-2 font-weight-bold">
                    {{ item.title }}
                  </span>
                  <br />
                  <span class="text-caption">
                    {{ item.description }}
                  </span>
                </td>
                <td align="center">
                  {{ item.type }}
                </td>
                <td align="center">
                  {{ item.createdAt | filterAsLocalDate() }}
                </td>
                <td class="actions">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-blur
                        text
                        small
                        v-bind="attrs"
                        v-on="on"
                        :href="item.url"
                        target="_blank"
                      >
                        <v-icon color="msaBlue"> mdi-export-variant </v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                  <NewsFeedDetail
                    :action="'edit'"
                    :newsFeed="item"
                    @submitNewsFeed="
                      submitNewsFeed($event);
                      reload = !reload;
                    "
                    @close="reload = !reload"
                    :key="reload"
                  />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-blur
                        text
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteNewsFeed(item.id)"
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      v-if="$vuetify.breakpoint.smAndDown"
      hide-default-header
      :loading="loading"
      :items="newsFeeds"
      :server-items-length="newsFeedCount"
      :footer-props="{
        'items-per-page-options': [20, 30, 50],
      }"
      :options.sync="options"
      :items-per-page.sync="perPage"
      :page.sync="page"
    >
      <template v-slot:item="{ item }">
        <v-card class="my-3">
          <v-card-title class="text-subtitle-1 msaBlue white--text">
            {{ item.title }}
          </v-card-title>
          <v-card-text class="pt-2 black--text">
            {{ item.description }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-blur
                  text
                  small
                  v-bind="attrs"
                  v-on="on"
                  :href="item.url"
                  target="_blank"
                >
                  <v-icon color="msaBlue"> mdi-export-variant </v-icon>
                </v-btn>
              </template>
              <span>View</span>
            </v-tooltip>
            <NewsFeedDetail
              :action="'edit'"
              :newsFeed="item"
              @submitNewsFeed="
                submitNewsFeed($event);
                reload = !reload;
              "
              @close="reload = !reload"
              :key="reload"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-blur
                  text
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteNewsFeed(item.id)"
                >
                  <v-icon color="red"> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import NewsFeedDetail from '@/components/NewsFeeds/NewsFeedDetail.vue';
export default {
  name: 'NewsFeeds',
  components: {
    SearchBar,
    NewsFeedDetail,
  },
  watch: {
    options: {
      handler() {
        this.getNewsFeedCount();
        this.getNewsFeeds();
      },
    },
    search: function () {
      this.getNewsFeedCount();
      this.getNewsFeeds();
    },
  },
  data() {
    return {
      reload: false,
      search: '',
      loading: false,
      page: this.$constants.PAGINATION.DEFAULT_PAGE,
      perPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      newsFeeds: [],
      newsFeedCount: 0,
      options: {},
      headers: [
        {
          text: 'Description',
          value: 'title',
          align: 'start',
          class: 'lightGrey',
        },

        {
          text: 'Type',
          value: 'type',
          align: 'center',
          class: 'lightGrey',
          width: '200px',
        },
        {
          text: 'Date added',
          value: 'createdAt',
          align: 'center',
          class: 'lightGrey',
          width: '120px',
        },
        {
          text: 'Actions',
          align: 'center',
          class: 'lightGrey',
          width: '190px',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getNewsFeeds() {
      this.loading = true;
      if (this.search == null) {
        this.search = '';
      }

      let params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        sortDesc:
          this.options.sortDesc[0] != undefined
            ? this.options.sortDesc[0]
            : true,
        sortBy:
          this.options.sortBy[0] != undefined
            ? this.options.sortBy[0]
            : 'createdAt',
      };

      let url = 'get-news-feeds?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.loading = false;
          this.newsFeeds = response.data;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    getNewsFeedCount() {
      if (this.search == null) {
        this.search = '';
      }

      let params = {
        search: this.search,
      };

      let url = 'get-news-feed-count?format=json';

      this.$axios
        .post(url, params)
        .then((response) => (this.newsFeedCount = response.data))
        .catch((error) => error);
    },
    submitNewsFeed(newsFeed) {
      let params = {
        newsFeed: newsFeed,
        loaderText: 'loading...',
      };

      let url = 'add-news-feed?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.newsFeeds = [];
          this.getNewsFeeds();
        })
        .catch((error) => error);
    },
    deleteNewsFeed(newsFeedId) {
      let params = {
        id: newsFeedId,
        loaderText: 'Deleting...',
      };

      let url = 'delete-news-feed?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.newsFeeds = [];
          this.getNewsFeeds();
        })
        .catch((error) => error);
    },
  },
  mounted() {},
};
</script>
<style></style>
